import { useEffect } from 'react';
import { useLoading, Bars } from '@agney/react-loading';
import CarroNativa from './nativafm_carro.svg';
import './App.css';

function App() {

  const { containerProps, indicatorEl } = useLoading({
    loading: true,
    indicator: <Bars width="100" />,
  });

  useEffect(() => {
    setTimeout(() => {
      window.location.replace('https://nativa.bigtester.com.br/?aff_id=3724');
    }, 3000);
  }, [])

  return (
    <div className="App">
      <header className="App-header">
        <img
          src={CarroNativa}
          alt="Promoção Carro da Nativa"
          style={{ maxWidth: '250px', marginBottom: 24 }}
        />
        <section {...containerProps}>
          {indicatorEl} {/* renders only while loading */}
        </section>
        <hr />
        <p>Você esta sendo redirecionado ao site do NativaFM Bigtester</p>
        <a
          className="App-link"
          href="https://nativa.bigtester.com.br"
          target="_blank"
          rel="noopener noreferrer"
        >
         Se não for redirecionado, clique aqui.
        </a>
      </header>
    </div>
  );
}

export default App;
